.container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.tooltipContent {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.whatsappButton {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  text-decoration: none;
}

.whatsappButton:hover {
  transform: scale(1.2);
}
.fontSize {
  font-size: 40px;
}

/* Media Queries para dispositivos móviles */
@media (max-width: 768px) {
  .whatsappButton {
    width: 60px;
    height: 60px;
  }
  .fontSize {
    font-size: 35px;
  }
}

@media (max-width: 480px) {
  .whatsappButton {
    width: 50px;
    height: 50px;
  }
  .fontSize {
    font-size: 30px;
  }
}
