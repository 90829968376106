/* @import url("https://api.fontshare.com/v2/css?f[]=author@400&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Indivisible:wght@400;700&display=swap"); */

@font-face {
  font-family: "Author-Regular";
  src: url("/Fonts/WEB/fonts/Author-Regular.woff2") format("woff2"),
    url("/Fonts/WEB/fonts/Author-Regular.woff") format("woff"),
    url("/Fonts/WEB/fonts/Author-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Indivisible-Regular";
  src: url("/Fonts/WEB/fonts/Indivisible-Regular.woff2") format("woff2"),
    url("/Fonts/WEB/fonts/Indivisible-Regular.woff") format("woff"),
    url("/Fonts/WEB/fonts/Indivisible-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/* Variables */
:root {
  /* //fiva */
  --fiva-color: #401c72;
  --fiva-secondary-color: #ddfffd;
  --Aguamarina-Fiva: #00f5ea;
  --fiva-grey: #f5f5f5;
  --fiva-bold-grey: #444444;
  --fiva-dark-grey: #949494;
  --fiva-darker-grey: #565656;
  --fiva-text-black: #292929;
  --fiva-green: #36a800;

  /* fonts */
  --font-indivisible: "Indivisible-Regular";

  /* font sizes */
  --font-size-sm: 0.88rem;
  --font-size-xl: 1.25rem;
  --font-size-base: 1rem;
  --font-size-21xl: 2.5rem;
  --font-size-5xl: 1.5rem;
  --font-size-13xl: 2rem;
  --font-size-9xl: 1.75rem;
  --font-size-29xl: 3rem;
  --font-size-53xl: 4.5rem;
  --font-size-17xl: 2.25rem;

  /* Colors */
  --blanco: #fff;
  --morado-fiva: #401c72;
  --color-gainsboro: #e5e5e5;
  --aguamarina-fiva: #00f5ea;
  --gris-claro: #f5f5f5;
  --azul-claro: #ddfffd;

  /* Gaps */
  --gap-3xs: 0.63rem;
  --gap-21xl: 2.5rem;
  --gap-base: 1rem;
  --gap-5xs: 0.5rem;
  --gap-mini: 0.94rem;
  --gap-11xs: 0.13rem;
  --gap-34xl: 3.31rem;
  --gap-7xs: 0.38rem;
  --gap-33xl: 3.25rem;

  /* Paddings */
  --padding-53xl: 4.5rem;
  --padding-61xl: 5rem;
  --padding-121xl: 8.75rem;
  --padding-81xl: 6.25rem;
  --padding-sm: 0.88rem;
  --padding-5xl: 1.5rem;
  --padding-13xl: 2rem;

  /* Border radiuses */
  --br-41xl: 60px;
  --br-31xl: 50px;
  --br-37xl: 56px;
  --br-38xl: 57px;
  --br-20xl: 39px;
}

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: "Author-Regular", sans-serif !important; */
  font-family: "Indivisible-Regular", sans-serif !important;
  font-size: 1.1em;
}

span,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  /* font-family: "Author-Regular", sans-serif !important; */
  font-family: "Indivisible-Regular", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**
* Bootstrap overwrite
*/
.form-control {
  min-height: 42px !important;
  border-radius: 30px !important;
  border: 2px solid var(--Aguamarina-Fiva) !important;
}

.form-control::placeholder {
  color: var(--fiva-color, #401c72) !important;
  font-size: 16px !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem var(--Aguamarina-Fiva) !important;
}

textarea.form-control {
  border-radius: 30px !important;
}

/**
* Antd overwrite
*/
.ant-typography {
  color: var(--fiva-color) !important;
}

.ant-pagination-options {
  display: none !important;
}

.ant-pagination-item-active {
  background-color: var(--fiva-color) !important;
  color: #ffffff !important;
  border-color: var(--fiva-color) !important;
  border-radius: 24px !important;
}

.ant-modal-content {
  border-radius: 50px !important;
  background-color: var(--fiva-secondary-color, #ddfffd) !important;
}

.row h2 {
  font-size: 2.5rem;
}

#custom-switch:checked {
  background-color: var(--fiva-color) !important;
  border-color: var(--Aguamarina-Fiva, #00f5ea) !important;
  color: var(--Aguamarina-Fiva, #00f5ea) !important;
}

.css-1twr7re-JoyAccordionSummary-button:not(.Mui-selected,
  [aria-selected="true"]):hover {
  background-color: inherit !important;
}

#formInput {
  color: var(--fiva-color, #401c72);
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  border-radius: 1.875rem;
  border: 2px solid var(--Aguamarina-Fiva, #00f5ea);
  background: var(--Blanco, #fff);
  text-align: start;
}

#formInput {
  width: 100%;
  /* Ajusta según sea necesario */
}

#formInput::placeholder {
  color: var(--Morado-Fiva, #401c72);
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  font-size: 16px;
}

#formInput::-ms-input-placeholder {
  /* Specific styles for Microsoft Edge */
  color: var(--Morado-Fiva, #401c72);
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 162.5% */
}

/* Hide spinner in number inputs */
#formInput::-webkit-inner-spin-button,
#formInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#formInput {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
  /* Rest */
}

.ant-ribbon-text {
  color: var(--fiva-color) !important;
}

/* Swiper */
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background: var(--Aguamarina-Fiva, #00f5ea) !important;
}

.swiper-pagination-bullet-active {
  background: var(--fiva-color, #401c72) !important;
}

/* swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal swiper-pagination-bullets-dynamic */

.calendly-inline-widget iframe {
  height: 750px !important;
}

.selected-flag {
  background: white !important;
  padding: 4rem;
}

.selected-flag {
  background-color: transparent !important;
}

/* BOOTSTRAP overwrite */
/* .mb-3{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
} */
/* Custom CSS to target the popover content */
.custom-popover-content {
  max-width: 300px !important;
  z-index: 1;
}

.consumption-popover-content {
  max-width: 320px !important;
  z-index: 1;
}

.round-checkbox .ant-checkbox-inner {
  border-radius: 50% !important;
  width: 20px;
  height: 20px;
  border-color: #00f5ea;
}

.round-checkbox {
  font-size: 16px !important;
}

.round-checkbox-bst {
  font-size: 14px !important;
}

.round-checkbox-bst .form-check-label {
  padding-top: 2px !important;
}

.round-checkbox-bst .form-check {
  font-size: 14px !important;
}

.form-check {
  padding-left: 1.8em !important;
}

.round-checkbox-bst .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #adb5bd;
  background-color: #fff;
  font-size: 16px !important;
  /* margin-left: 0px !important; */
}

.round-checkbox-bst .form-check-input:checked {
  background-color: var(--Aguamarina-Fiva);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>');
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

.round-checkbox-bst .form-check-input:hover {
  border-color: var(--Aguamarina-Fiva);
}

.ant-modal-title {
  background-color: var(--fiva-secondary-color, #ddfffd) !important;
  padding-left: 1rem !important;
  color: var(--fiva-bold-grey, #292929) !important;
}

span.anticon.anticon-close.ant-modal-close-icon {
  /* padding-right: 2rem !important; */
  font-size: 20px;
  color: var(--fiva-bold-grey, #292929) !important;
}

.ant-upload-select {
  border: 1.5px dashed var(--Aguamarina-Fiva) !important;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload-list-picture-card .ant-upload {
  background-color: #ddfffd;
  border-radius: 8px;
}

.custom-dragger .ant-upload-drag {
  border-color: var(--Morado-Fiva, #401c72) !important;
  border-radius: 50px;
}

.ant-modal-confirm-body-has-title {
  justify-content: center !important;
}


.Mui-selected {
  background-color: var(--Aguamarina-Fiva, #00F5EA) !important;
}

@media screen and (max-width: 850px) {
  #formInput::placeholder {
    font-size: 16px !important;
  }

  .ant-upload-wrapper .ant-upload-list {
    justify-content: center !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .ant-modal-content {
    border-radius: 20px !important;
    background-color: var(--fiva-secondary-color, #ddfffd) !important;
  }

  .custom-dragger .ant-upload-drag {
    border-color: var(--Morado-Fiva, #401c72) !important;
    border-radius: 20px;
  }
}